import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { DemoAccount, Gateway, Grpcategory, Payment } from "src/_models";



const API_URL = '/api/metaaccounts/';
const API_URL2 = '/api/payment/';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class MetaaccountsService {
    findMetaleveragesBylogin(login: number) {
        return this.http.post<any[]>(API_URL + `leveragesbylogin`, login);//.pipe(
    }
    saveNickname(account: any) {
        return this.http.post<any>(API_URL + `nickname`, account);//.pipe(
    }
    saveLeverage(account: any) {
        return this.http.post<any>(API_URL + `demo/changeleverage`, account);//.pipe(
    }
    saveFund(account: any) {
        return this.http.post<any>(API_URL + `demo/fund`, account);//.pipe(
    }
    savePassword(account: any) {
        return this.http.post<any>(API_URL + `password`, account);//.pipe(
    }
    constructor(private http: HttpClient) { }

    findMetaGroupdetailss(): Observable<Grpcategory[]> {
        return this.http.post<Grpcategory[]>(API_URL + `metagrpdetails`, {});//.pipe(
    }

    findMetaGroups(): Observable<any[]> {
        return this.http.post<any[]>(API_URL + `accttypes`, {});//.pipe(
    }
    findMetaleverages(): Observable<any[]> {
        return this.http.post<any[]>(API_URL + `leverages`, {});//.pipe(
    }
    createDemo(demo: DemoAccount): Observable<any> {
        return this.http.post<any>(API_URL + `demoacct/create`, demo);//.pipe(
    }

    createLive(demo: DemoAccount): Observable<any> {
        return this.http.post<any>(API_URL + `liveacct/create`, demo);//.pipe(
    }

    findPaymentgateways() {
        return this.http.post<Gateway[]>(API_URL2 + `gateways`, {});//.pipe(
    }

    saveLiveFund(payment: Payment) {
        // alert('Service Submitting!'+JSON.stringify(demo));

        //  this.http.post(API_URL+`liveacct/fund`,demo );//.pipe(
        //  return
        this.http.post(`/api/payment/makepayment`, payment).subscribe({
            next(data) {
                console.log(data);
                let result = JSON.stringify(data);
                let url='';
                console.log(result);
                var jsonParsedArray = JSON.parse(result);
                
                let f = document.createElement("form");
                f.setAttribute("method", "post");
                //f.setAttribute("target", "_blank");
                for (const key in jsonParsedArray) {
                    console.log(key);
                    if(key==='redirect_url'){
                        url=jsonParsedArray[key];
                        alert(url)
                    }
                    if (jsonParsedArray.hasOwnProperty(key)) {
                     //   console.log("%c " + key + " = " + jsonParsedArray[key]);
                        let j = document.createElement("input");
                        j.type = "hidden";
                        j.name = key;
                        j.value = jsonParsedArray[key];
                        f.appendChild(j);  
                    }
                }
                f.setAttribute(
                    "action",
                     url
                ); //Your action URL
/*
                let j = document.createElement("input");
                j.type = "hidden";
                j.name = 'amount';
                j.value = payment.totalAmount.toString();
                f.appendChild(j);
                let k = document.createElement("input");
                k.type = "hidden";
                k.name = 'paymode';
                k.value = '500034596';
                f.appendChild(k);
                let y = document.createElement("input");
                y.type = "hidden";
                y.name = 'login';
                y.value = payment.login.toString();
                f.appendChild(y);
                let z = document.createElement("input");
                z.type = "hidden";
                z.name = 'currency';
                z.value = 'USD';
                f.appendChild(z);
                let b = document.createElement("input");
                b.type = "hidden";
                b.name = 'cancel_url';
                b.value = 'https://www.winstonefx.com/';
                f.appendChild(b);*/
                //create hidden input elements
                /*config.keys.forEach((item) => {
                  let i = document.createElement("input");
                  i.type = "hidden";
                  i.name = item.name;
                  i.value = item.value;
                  f.appendChild(i); //Add it to the form
                });*/

                document.body.appendChild(f);//Add the form to the body
              f.submit(); //Submit it immediately
            },
            error(msg) {
                alert(msg);
                console.log('Error Getting Location: ', msg);
            }
        });//  .pipe((response: Response) => response.json());
        //then(function(response) {
        // do stuff
    }
    /*, function (response) {
        var headers = response.headers();
        if ('x-redirect' in headers)
        {
            document.location.href = headers['x-redirect'];
        }
        return response;
    })*/

    saveLiveFundss(demo: any) {
        let url = 'https://pay.skrill.com';
        let payload = { pay_to_email: '', amount: 0, transaction_id: 0, recipient_description: '', currency: '' };
        payload.pay_to_email = 'epay@winstoneprime.com';
        payload.amount = 5;
        payload.currency = 'USD';
        payload.transaction_id = 100102;
        payload.recipient_description = 'Winstone Prime Payment for the login 1152';

        // this.http.post(url,payload);

        let f = document.createElement("form");
        f.setAttribute("method", "post");
        //f.setAttribute("target", "_blank");
        f.setAttribute(
            "action",
            "http://my.winstonefx.com:8190/payment/makepayment"
        ); //Your action URL

        let j = document.createElement("input");
        j.type = "hidden";
        j.name = 'amount';
        j.value = demo.balance;
        f.appendChild(j);
        let k = document.createElement("input");
        k.type = "hidden";
        k.name = 'paymode';
        k.value = '500034596';
        f.appendChild(k);
        let y = document.createElement("input");
        y.type = "hidden";
        y.name = 'login';
        y.value = demo.login;
        f.appendChild(y);
        let z = document.createElement("input");
        z.type = "hidden";
        z.name = 'currency';
        z.value = 'USD';
        f.appendChild(z);
        let b = document.createElement("input");
        b.type = "hidden";
        b.name = 'cancel_url';
        b.value = 'https://www.winstonefx.com/';
        f.appendChild(b);
        //create hidden input elements
        /*config.keys.forEach((item) => {
          let i = document.createElement("input");
          i.type = "hidden";
          i.name = item.name;
          i.value = item.value;
          f.appendChild(i); //Add it to the form
        });*/

        document.body.appendChild(f);//Add the form to the body
        f.submit(); //Submit it immediately

        // window.location.href = "http://localhost:8080/liveacct/fund";
        // window.location.href = "https://google.com/about";
    }

}