import { NumericLiteral } from "typescript";

export class Mt4TradeModel {
    login:number;
    ticket:number;
    symbol:string;
    digits:number;
    cmd:number;
    ordertype:string;
    volume:number;
    openiime:string;
    openprice:number;
    sl:number;
    tp:number;
    closetime:string;
    commission:number;
    commissionagent:number;
    swaps:number;
    closeprice:number;
    profit:number;
    comment:string;  
    expiration:number;
    reasonint:number;
    reason:string;
}
export class Mt5TradeModel {
    login:number;
    ticket:number;
    clticket:number;
    symbol:string;
    digits:number;
    action:number;
    ordertype:string;
    volume:number;
    opentime:string;
    openprice:number;
    pricesl:number;
    pricetp:number;
    closetime:string;
    commission:number;
    swaps:number;
    closeprice:number;
    profit:number;
    comment:string;  
    fee:number;
    reasonint:number;
    reason:string;
}
export class TradeModel {
    login:number;
    ticket:number;
    sl: number;
    tp: number;
    corder: number;
    reason: string;
    symbol:string;
    digits:number;
    cmd:number;
    ordertype:string;
    openvolume:number;
    closevolume:number;
    openTime:string;
    openPrice:number;
    
    closeTime:string;
    commission:number;
    swaps:number;
    closePrice:number;
    profit:number;
    comment:string;
    lptime1: number=0;
    lporderid1: string='';
    direction1: string='';
    percent1: number=0;
    lpvolume1: number=0;
    reqprice1: number=0;
    lpprice1: number=0;
    slip: number=0;
    markup: number=0;
    server:string;
    action:number;
    clientnetpl:number;
    markup1: number=0;
    markup2: number=0;
    slip1: number=0;
    slip2: number=0;
    csize:number;
    lpswap:number;
    lpnetprofit:number;
    openprice:number;
    closeprice:number;
    lpopenprice:number;
    lpcloseprice:number;
    lpcomm: number=0;
    lpprofit: number=0;
    lppnetrofit: number=0;
    lpticket:number;
    lpclticket:number;
    ocommission:number;
    commissionAgent:number;
    lpclosevolume:number;
   tcommission:number;
     
}
export class LpprofitModel {
    login:number;
    ticket:number;
    openprice:number;
    closeprice:number;
    corder: number;
    symbol:string;
    digits:number;
    cmd:number;
    ordertype:string;
    openvolume:number;
    closevolume:number;
    openTime:string;
    openPrice:number;
    tp:number;
    closeTime:string;
    commission:number;
    swaps:number;
    closePrice:number;
    profit:number;
    comment:string;
    lptime1: number=0;
    lporderid1: string='';
    direction1: string='';
    percent1: number=0;
    lpvolume1: number=0;
    reqprice1: number=0;
    lpopenprice: number=0;
    lpcloseprice: number=0;
    lpprice1: number=0;
    lpcomm: number=0;
    lpswap: number=0;
    lpprofit: number=0;
    slip: number=0;
    markup: number=0;
    server:string;
    action:number;
    clientnetpl:number;
    markup1: number=0;
    markup2: number=0;
    slip1: number=0;
    slip2: number=0;
    csize:number;
     lpticket:number;
     lpclticket:number;
    lpnetprofit:number;
    lpclosevolume:number;
    volumeclosed:number;
     
}

export class OpenTrades {
    Login:number;
    Position:number;
    Symbol:string;
    Digits:number;
    ContractSize:number;
    Action:number;
    ordertype:string;
    volume:number;
    TimeCreate:number;
    TimeCreateMsc:number;
    opentime:Date;
    PriceOpen:number;
    PriceCurrent:number;
    PriceSL:number;
    PriceTP:number;
    Volume:number;
    Storage:number;
    closeprice:number;
    Profit:number;
    Comment:string;
    group1: string;
    percent1: number=0;
    percent:number=0;
    slip: number=0;
    markup: number=0;
    server:string;
    Time:Date;
    TimeOpen:string;
    lpvolume:number;
    bvol:number;
    aprofit:number;
    bprofit:number;
    HId:number;
    HPosition:number;
    HVolume:number;
    HRemainVolume:number;
    HLogin:number;
     
}

export class OpenMeta {
    login:number;
    ticket:number;
    symbol:string;
    digits:number;
    ContractSize:number;
    Action:number;
    ordertype:string;
    volume:number;
    TimeCreate:number;
    TimeCreateMsc:number;
    opentime:Date;
    openprice:number;
    closeprice:number;
    PriceSL:number;
    PriceTP:number;
    
    storage:number;
  
    profit:number;
    Comment:string;
    group1: string;
    percent: number=0;
    slip: number=0;
    markup: number=0;
    server:string;
    Time:Date;
    TimeOpen:string;
    lpvolume:number;
    bvol:number;
    aprofit:number;
    bprofit:number;
     
}

export class TradeRequest {
    id:number;
    dbid:number;
    login:number;
    ticket:number;
    symbol:string;
    
    action1:number;
    ordertype:string;
    volume:number;
    digits:number;
    openprice:number;
    closeprice:number;
    PriceSL:number;
    PriceTP:number;
    hlogin:number;
    hvolume:number;
    hremainvolume:number;
    group1: string;
    percent: number=0;
    
     
}
