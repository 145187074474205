export class Pagination {
    pageSize:number=10;
    page:number=1;
    search:string='';
    orderby:string='';
    orderdir:string='';
    searchText:string='';
    login:number=0;
    ticket:number=0;
    symbol:string="";
    fromdate:string="";
    todate:string="";

}
