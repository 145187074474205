import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';



const API_URL = '/api/traders/';
  

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}; 

@Injectable({ providedIn: 'root' })
export class TradersService {
    constructor(private http: HttpClient) { }

    findAccounts():Observable<any>
    {
        return this.http.post(API_URL+`demo/accounts`,"DEMO" );//.pipe(
    }
    findLiveAccounts():Observable<any>
    {
        return this.http.post(API_URL+`live/accounts`,"LIVE" );//.pipe(
    }
    findMaster():Observable<any>
    {
        return this.http.post(API_URL+`masterdata`,{} );//.pipe(
    }
    findLanguages():Observable<any>
    {
        return this.http.post(API_URL+`languages`,{} );//.pipe(
    }
    findDoctypes():Observable<any>
    {
        return this.http.post(API_URL+`doctypes`,{} );//.pipe(
    }

    findPrefermethods():Observable<any>
    {
        return this.http.post(API_URL+`prefermethods`,{} );//.pipe(
    }
    findEmpstatuses():Observable<any>
    {
        return this.http.post(API_URL+`empstatuses`,{} );//.pipe(
    }
    findEducationlevels():Observable<any>
    {
        return this.http.post(API_URL+`educationlevels`,{} );//.pipe(
    }
    findWealthsrcs():Observable<any>
    {
        return this.http.post(API_URL+`wealthsrcs`,{} );//.pipe(
    }
    findEmpindustries():Observable<any>
    {
        return this.http.post(API_URL+`empindustries`,{} );//.pipe(
    }
    storePersonal(personal:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storePersonal`,personal );//.pipe(
    }
    storeAddress(address:any,file:File):Observable<any>
    {
        const formData: FormData = new FormData();

    formData.append('file1', file);
    formData.append('address', new Blob([JSON.stringify(address)], {
        type: "application/json"
    }));
   // address.file1=file;
        return this.http.post<any>(API_URL+`storeAddress`,formData );//.pipe(
    }
    storeCommunication(communication:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storeCommunication`,communication );//.pipe(
    }
    storeRegulation(regulation:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storeRegulation`,regulation );//.pipe(
    }
    storeFinance(finance:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storeFinance`,finance );//.pipe(
    }

    storeIdproof(idproof:any,file:File):Observable<any>
    {
        const formData: FormData = new FormData();

    formData.append('file1', file);
    formData.append('idproof', new Blob([JSON.stringify(idproof)], {
        type: "application/json"
    }));
   // address.file1=file;
        return this.http.post<any>(API_URL+`storeIdproof`,formData );//.pipe(
    }
}