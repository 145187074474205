import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { AccountService } from "./account.service";



const API_URL = '/api/profile/';
  

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}; 

@Injectable({ providedIn: 'root' })
export class ProfileService {

    public kycupdate:boolean=true;
    constructor(private http: HttpClient) { }


    findKycupdate():void{
       
       
    }

    findProfile():Observable<any>
    {
        return this.http.post(API_URL+`find`,{} );//.pipe(
    }
    getProfile():Observable<any>
    {
        return this.http.post(API_URL+`profiledata`,{} );//.pipe(
    }
    findMaster():Observable<any>
    {
        return this.http.post(API_URL+`masterdata`,{} );//.pipe(
    }
    findLanguages():Observable<any>
    {
        return this.http.post(API_URL+`languages`,{} );//.pipe(
    }
    findDoctypes():Observable<any>
    {
        return this.http.post(API_URL+`doctypes`,{} );//.pipe(
    }

    findPrefermethods():Observable<any>
    {
        return this.http.post(API_URL+`prefermethods`,{} );//.pipe(
    }
    findEmpstatuses():Observable<any>
    {
        return this.http.post(API_URL+`empstatuses`,{} );//.pipe(
    }
    findEducationlevels():Observable<any>
    {
        return this.http.post(API_URL+`educationlevels`,{} );//.pipe(
    }
    findWealthsrcs():Observable<any>
    {
        return this.http.post(API_URL+`wealthsrcs`,{} );//.pipe(
    }
    findEmpindustries():Observable<any>
    {
        return this.http.post(API_URL+`empindustries`,{} );//.pipe(
    }
    storePersonal(personal:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storePersonal`,personal );//.pipe(
    }
    storeAddress(address:any,file:File):Observable<any>
    {
        const formData: FormData = new FormData();

    formData.append('file1', file);
    formData.append('address', new Blob([JSON.stringify(address)], {
        type: "application/json"
    }));
   // address.file1=file;
        return this.http.post<any>(API_URL+`storeAddress`,formData );//.pipe(
    }
    storeCommunication(communication:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storeCommunication`,communication );//.pipe(
    }
    storeRegulation(regulation:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storeRegulation`,regulation );//.pipe(
    }
    storeFinance(finance:any):Observable<any>
    {
        return this.http.post<any>(API_URL+`storeFinance`,finance );//.pipe(
    }

    storeIdproof(idproof:any,file:File):Observable<any>
    {
        const formData: FormData = new FormData();

    formData.append('file1', file);
    formData.append('idproof', new Blob([JSON.stringify(idproof)], {
        type: "application/json"
    }));
   // address.file1=file;
        return this.http.post<any>(API_URL+`storeIdproof`,formData );//.pipe(
           
    }

    downloadAddressFile(id: number):any {
        const url = `${API_URL}/addressfile/${id}`;
        return this.http.get(url, {responseType: 'blob'});
      //  return this.http.post<Blob>(`${API_URL}/addressfile/${id}`,{});
           
    }

    downloadIdFile(id: number):any {
        const url = `${API_URL}/idfile/${id}`;
        return this.http.get(url, {responseType: 'blob'});
      //  return this.http.post<Blob>(`${API_URL}/addressfile/${id}`,{});
           
    }

    findAddress(id:number):Observable<any>
    {
        const url = `${API_URL}/address/find/${id}`;
        return this.http.post(url,{} );//.pipe(
    }
    deleteAddress(id:number):Observable<any>
    {
        const url = `${API_URL}/address/delete/${id}`;
        return this.http.post(url,{} );//.pipe(
    }

    findIdproof(id:number):Observable<any>
    {
        const url = `${API_URL}/idproof/find/${id}`;
        return this.http.post(url,{} );//.pipe(
    }
    deleteIdproof(id:number):Observable<any>
    {
        const url = `${API_URL}/idproof/delete/${id}`;
        return this.http.post(url,{} );//.pipe(
    }

}