import { Role } from './role';

export class Account {
    id: string;
    title: string;
    firstname: string;
    lastname: string;
    email: string;
    role: Role;
    verified:number;
    accessToken?: string;
}


export class   SignupRequest {
    email: string;
    username: string;
    firstname: string;
    lastname: string;
    password: string;
    confirmPassword: string;
    acceptTerms:boolean;
    
}

export class Verified {
   
    email: string;
    emailotp: string;
    mobileopt: string;
    emailverified:boolean;
    mobileverified:boolean;
    idverified:boolean;
    kycupdate:boolean=false;
}