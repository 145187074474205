export class Grpcategory {
    id: number;
    catename: string;
    features: Grpfeatures[];
}

export class Grpfeatures {
    featureid: number;
    slno: number;
    feature: string;
}

export class AccountCategory {
    id: number;
    catename: string;
    minleverage: number;
    maxleverage:number;
    defaultleverage:number;
}
export class Leverage {
    leverage: number;
    
}

export class Gateway {
    id: number; 
    gatewayname: string;
    currency: string;
    depositflatfee: number;
    depositfee: number;
    depositfeetype:number;
    depositminfee: number;
    depositminfeetype:number;
   // leverage: number;
    
}

export class Payment {
    gatewayid: number; 
    gatewayname: string;
    currency: string;
    login: number;
    totalAmount: number;
     
   // leverage: number;
    
}
export class DemoAccount {
    leverage: number;
    acctgroup: number;
    password:string;
    confirmPassword: string;
    nickname: string;

}