import { Account } from 'src/_models';
import { AccountService } from '../_services';

export function appInitializer(accountService: AccountService) {
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        let temp = undefined;
        temp=resolve;
     //   console.log(temp)
        accountService.refreshToken()
            .subscribe()
            .add(temp);
            
    });
}