import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import icShoppingBag from '@iconify/icons-ic/twotone-shopping-bag';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;
  icShoppingBag = icShoppingBag;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {}
}
