<div class="footer flex">
  <div class="flex-auto flex items-center" vexContainer>
    <a class="action flex-none"
       color="primary"
       href="https://www.winstoneprime.com"
       id="get-vex"
       mat-flat-button>
      <ic-icon [icon]="icShoppingBag" [inline]="true" class="ltr:mr-2 rtl:ml-2" size="18px"></ic-icon>
      <span></span>
    </a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      WS Prime
    </div>
  </div>
</div>
