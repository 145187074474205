import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  TradeModel, TradeRequest } from '../_models';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

const API_TRADES_URL = '/api/trades/';
 

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}; 


@Injectable({
  providedIn: 'root'
})
export class HedgingService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  trades:TradeModel[]=[];
  tradesProfit:number=0;
  private trades$ = new BehaviorSubject<TradeModel[]>([]);
  size$ = new BehaviorSubject<number>(0);
   
   
  constructor(private http:HttpClient) { }
  get loading$() { return this._loading$.asObservable(); }
  load(): void {
    let positionses=[];
    this.findPositions().subscribe(positions =>{
      this.trades = positions;
      var total1=  positions.reduce(function(sum, current) {
        return sum + (+current.profit);
      }, 0); // 43 + 32 + 21 = 96
      this.tradesProfit=total1;
    });
     
  }
 

  find(): Observable<TradeModel[]> {
    return this.http.post(API_TRADES_URL+`opentrades/list`,{} ).pipe(
      map((response: any) => {
        this.size$.next(response.totalCount);
        return response;
      })
    );
  }
  findPositions(): Observable<any[]> {
    return this.http.post(API_TRADES_URL+`opentrades/positions`,{} ).pipe(
      map((response: any) => {
     //   this.size$.next(response.totalCount);
        return response;
      })
    );
  }
  hedgePosition(request:TradeRequest): Observable<any> {
    return this.http.post(API_TRADES_URL+`hedgeposition`,request ).pipe(
      map((response: any) => {
        console.log(JSON.stringify(response))
        return response;
      })
    );
  }
  hedgeRemovePosition(request:TradeRequest): Observable<any> {
    return this.http.post(API_TRADES_URL+`hedgeremove`,request ).pipe(
      map((response: any) => {
        console.log(JSON.stringify(response))
        return response;
      })
    );
  }
  
  
  
}
